<template>
  <section>
    <article class="container text-left">
      <div class="statistics">
        <h2>User Statistics</h2>
        <div id="chart">
          <div>
            <apexchart
              width="100%"
              height="400px"
              type="area"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [2020, 2021, 2022, 2023, 2024],
        },
      },
      series: [
        {
          name: "series-1",
          data: [50, 49, 60, 70, 91],
        },
      ],
    };
  },
};
</script>

<style scoped >
.statistics {
  height: 100%;
  border-radius: 10px;
  margin-top: 20px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 15px;
}
.statistics h2 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 30px;
}
</style>